import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';

const EmailModal = ({ form, visible, onCancel, studyId, invite }) => {
  const { getFieldDecorator } = form;

  const validateEmailFormat = (rule, value) => {
    // eslint-disable-next-line
    const regex = /^(([a-zA-Z0-9_\.\-]+)@([a-zA-Z0-9_\.\-]+)\.([a-zA-Z]{2,5})\s*,?\s*)+$/;
    if (value && value.match(regex)) {
      return Promise.resolve();
    }
    // eslint-disable-next-line
    return Promise.reject('One or more invalid email adresses and/or invalid separators');
  };

  const onOk = (e) => {
    e.preventDefault();
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      const { emails: emailsString } = values;
      const trimmedEmails = emailsString
        .split(/,\s*/g)
        .map((email) => email.trim())
        .filter((email) => email.length);
      const uniqueEmails = [...new Set(trimmedEmails)];
      const updatedValues = {
        studyId,
        invitations: uniqueEmails.map((email) => ({ email })),
      };
      form.resetFields();
      invite(updatedValues);
    });
  };

  return (
    <Modal
      visible={visible}
      title="Invite new participants"
      okText="Send Invites"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form layout="horizontal">
        <Form.Item required extra="Enter one or more emails separated by comma.">
          {getFieldDecorator('emails', {
            rules: [
              { required: true, message: 'This field is required.' },
              {
                validator: validateEmailFormat,
              },
            ],
            validateTrigger: 'onSubmit',
          })(<Input addonBefore="Email" placeholder="bee@example.com, wasp@example.com" />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

EmailModal.propTypes = {
  form: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  studyId: PropTypes.string.isRequired,
  invite: PropTypes.func.isRequired,
};

EmailModal.defaultProps = {
  form: {},
  getFieldDecorator: null,
};

export default Form.create()(EmailModal);
