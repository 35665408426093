import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';

const EmailModal = ({ form, visible, onCancel, sendEmail, studyId, studyName }) => {
  const { getFieldDecorator } = form;

  const onOk = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      const { subj, message } = values;
      const body = message.replace(/\r?\n/g, '<br />');
      const subject = `News from ${studyName}: ${subj}`;
      const updatedValues = { studyId, subject, body };
      if (!err) {
        sendEmail(updatedValues);
      }
    });
  };

  return (
    <Modal
      visible={visible}
      title="Email all participants"
      okText="Send email"
      onCancel={onCancel}
      onOk={onOk}
    >
      <Form>
        <Form.Item label="Subject" colon={false}>
          {getFieldDecorator('subj', {
            rules: [{ required: true, message: 'Please enter an email subject' }],
          })(<Input type="text" placeholder="E.g. The study is closing in 30 days" />)}
        </Form.Item>

        <Form.Item label="Message" colon={false}>
          {getFieldDecorator('message', {
            rules: [{ required: true, message: 'Please choose a message to the participants.' }],
          })(<Input.TextArea rows={12} placeholder="Dear participant," />)}
        </Form.Item>
      </Form>
    </Modal>
  );
};

EmailModal.propTypes = {
  form: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  sendEmail: PropTypes.func.isRequired,
  studyId: PropTypes.string.isRequired,
  studyName: PropTypes.string.isRequired,
};

EmailModal.defaultProps = {
  form: {},
  getFieldDecorator: null,
};

export default Form.create()(EmailModal);
