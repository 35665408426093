import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Table, Badge, Icon, Popconfirm, Button } from 'antd';

import InviteModal from './InviteModal';
import EmailModal from './EmailModal';
import StyledActionIcons from './StyledActionIcons';

const ManageParticipants = ({
  invitations,
  studyId,
  studyName,
  onInvite,
  onReinvite,
  onDelete,
  onEmail,
}) => {
  const [showInviteForm, setShowInviteForm] = useState(false);
  const [showEmailForm, setShowEmailForm] = useState(false);

  const showInviteModal = () => {
    setShowInviteForm(true);
  };

  const handleCancelInvite = () => {
    setShowInviteForm(false);
  };

  const showEmailModal = () => {
    setShowEmailForm(true);
  };

  const handleCancelEmailModal = () => {
    setShowEmailForm(false);
  };

  const handleDeleteParticipant = (paritciapantId) => {
    onDelete(paritciapantId);
  };

  const handleReinvite = (participantId) => {
    onReinvite(participantId);
  };

  const columns = [
    {
      title: 'Participant',
      dataIndex: 'email',
    },
    {
      title: 'Invitation Status',
      dataIndex: 'state',
      render: (text) => (
        <Badge status={text ? 'success' : 'default'} text={text ? 'Accepted' : 'Pending'} />
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      render: (text, record) => (
        <StyledActionIcons>
          {!record.state ? (
            <Popconfirm
              placement="bottomLeft"
              title="Do you want to send another invitation to this participant?"
              okText="Send"
              onConfirm={() => handleReinvite(record.key)}
            >
              <Icon type="mail" className="action-icon" />
            </Popconfirm>
          ) : null}
          <Popconfirm
            placement="bottomLeft"
            title="Are you sure you want to delete this participant? This action cannot be undone"
            okText="Delete"
            onConfirm={() => handleDeleteParticipant(record.key)}
          >
            <Icon type="delete" className="action-icon" />
          </Popconfirm>
        </StyledActionIcons>
      ),
    },
  ];

  const invitationsWithKey = invitations.map((invitation) => ({
    ...invitation,
    key: invitation.id,
  }));

  const hasActiveParticipants = invitations.some((invitation) => invitation.state);

  return (
    <>
      <Row type="flex" justify="center" style={{ marginBottom: '12px' }}>
        <Col span={24}>
          <Table columns={columns} dataSource={invitationsWithKey} pagination={{ pageSize: 20 }} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button icon="user-add" size="large" onClick={showInviteModal}>
            Add participants
          </Button>

          {hasActiveParticipants && (
            <Button
              icon="user-add"
              size="large"
              onClick={showEmailModal}
              style={{ marginLeft: `${20}px` }}
            >
              Email all participants
            </Button>
          )}
        </Col>
      </Row>
      {showInviteForm && (
        <InviteModal
          visible={showInviteForm}
          onCancel={handleCancelInvite}
          invite={onInvite}
          studyId={studyId}
        />
      )}
      {showEmailForm && (
        <EmailModal
          visible={showEmailForm}
          onCancel={handleCancelEmailModal}
          sendEmail={onEmail}
          studyId={studyId}
          studyName={studyName}
        />
      )}
    </>
  );
};

ManageParticipants.defaultProps = {
  invitations: [],
  onDelete: null,
  onInvite: null,
  onReinvite: null,
  onEmail: null,
};

ManageParticipants.propTypes = {
  invitations: PropTypes.array,
  onDelete: PropTypes.func,
  onInvite: PropTypes.func,
  onReinvite: PropTypes.func,
  onEmail: PropTypes.func,
  studyId: PropTypes.string.isRequired,
  studyName: PropTypes.string.isRequired,
};

export default ManageParticipants;
