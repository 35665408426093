import styled from 'styled-components';

import theme from './Theme';

const StyledFooter = styled.div`
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #76a2c9ed;
  padding: ${theme.spacingS} 0;
  text-align: center;
  border: 1px #0c3863 solid;
`;

export default StyledFooter;
