import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, Button, Modal } from 'antd';

import IconUpload from '../IconUpload';
import { DATA_OPTIONS } from '../../lib/constants';
import { Select } from '../FormFields';
import StyledForm from '../../styled/shared/StyledForm';
import StyledFooter from '../../styled/shared/StyledFooter';

const StudyForm = ({ study, form, onSubmit, invitations }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showGDPRNotice, setShowGDPRNotice] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);

  const formItemLayout = {
    // The layout of label
    labelCol: {
      span: 24,
    },
    // The layout for input controls
    wrapperCol: {
      span: 24,
    },
  };

  const institutionToIcon = (institution) => ({
    uid: institution.id,
    url: institution.logo,
    type: institution.logoContentType,
  });

  const iconToInstitution = (icon) => ({
    // TODO discuss w Fernando about adding name to the API
    name: icon.name,
    logo: icon.url,
    logoContentType: icon.type,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const { requestedData, institutionIcons, icon, ...rest } = values;
        const updatedValues = {
          ...rest,
          icon: icon[0].url,
          iconContentType: icon[0].type,
          institutions: institutionIcons.map(iconToInstitution),
          requestedData: requestedData.toString(),
        };
        const hasActiveParticipants = invitations.some((invitation) => invitation.state);
        const requestedDataChanged =
          updatedValues.requestedData !== study.requestedData ||
          updatedValues.dataJustification !== study.dataJustification;
        const shouldSubmit = hasActiveParticipants
          ? !requestedDataChanged || (requestedDataChanged && confirmSubmit)
          : true;
        if (shouldSubmit) {
          setIsLoading(true);
          onSubmit(updatedValues).then(() => setIsLoading(false));
        } else {
          setShowGDPRNotice(true);
        }
      } else console.log(err);
    });
  };

  const { getFieldDecorator } = form;
  const files =
    study && study.id ? [{ uid: study.id, url: study.icon, type: study.iconContentType }] : [];
  const hasMoreIcons = study && study.id && study.institutions.length > 0;
  const institutionFiles = hasMoreIcons ? study.institutions.map(institutionToIcon) : [];
  return (
    <>
      <Row type="flex" justify="center" style={{ marginBottom: '12px' }}>
        <Col lg={{ span: 16 }} md={{ span: 20 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <StyledForm {...formItemLayout} id="edit-study" onSubmit={handleSubmit}>
            <StyledForm.Item
              className="form-item upload"
              required
              label={
                <>
                  <div className="question-number">1</div>Study Icon
                </>
              }
              colon={false}
            >
              {getFieldDecorator('icon', {
                initialValue: files,
                rules: [
                  { required: true, message: 'Please enter a study icon' },
                  {
                    validator: (rule, value, callback) => {
                      try {
                        if (value.length > 1) {
                          throw new Error('Maximum of 1 icon!');
                        }
                      } catch (err) {
                        callback(err);
                        return;
                      }
                      callback();
                    },
                    message: 'Please remove 1 icon. Only 1 study icon can be uploaded',
                  },
                  {
                    validator: (rule, value, callback) => {
                      console.log(value);
                      try {
                        if (value[0] && value[0].size > 250000) {
                          throw new Error('Maximum size for icons is 250KB!');
                        }
                      } catch (err) {
                        callback(err);
                        return;
                      }
                      callback();
                    },
                    message: 'Please make sure provided icon is under 250KB',
                  },
                ],
              })(<IconUpload files={files} placeholder="Study Icon" />)}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              required
              label={
                <>
                  <div className="question-number">2</div>Study Name
                </>
              }
              colon={false}
              extra="Give your study a name. You can use up to 50 characters."
            >
              {getFieldDecorator('name', {
                initialValue: study.name,
                rules: [{ required: true, message: 'Please enter a study name' }],
              })(<Input type="text" name="name" placeholder="Study Name" maxLength="50" />)}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              required
              label={
                <>
                  <div className="question-number">3</div>Study Description
                </>
              }
              colon={false}
              extra="Enter a short paragraph to describe the study to the participants. You can use up to 400 characters."
            >
              {getFieldDecorator('description', {
                initialValue: study.description,
                rules: [{ required: true, message: 'Please enter a description' }],
              })(
                <Input.TextArea
                  rows={4}
                  type="textarea"
                  placeholder="Study Description"
                  maxLength="400"
                />,
              )}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              required
              label={
                <>
                  <div className="question-number">4</div>Contact e-mail
                </>
              }
              colon={false}
            >
              {getFieldDecorator('contactEmail', {
                initialValue: study.contactEmail,
                rules: [
                  { required: true, message: 'Please enter an email' },
                  { type: 'email', message: 'Please enter a valid email' },
                ],
              })(<Input type="email" placeholder="Contact email" />)}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              required
              label={
                <>
                  <div className="question-number">5</div>Data Required
                </>
              }
              colon={false}
            >
              {getFieldDecorator('requestedData', {
                initialValue: study.requestedData && study.requestedData.split(','),
                rules: [{ required: true, message: 'Please select one or more data inputs' }],
              })(<Select placeholder="Select Study Data" options={DATA_OPTIONS} />)}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              required
              label={
                <>
                  <div className="question-number">6</div>Data Collection Justification
                </>
              }
              extra="For GDPR reasons you have to define why you need to collect each data point. Please mention each one you have selected above here."
              colon={false}
            >
              {getFieldDecorator('dataJustification', {
                initialValue: study.dataJustification,
                rules: [{ required: true, message: 'Please enter a justification' }],
              })(
                <Input.TextArea
                  rows={4}
                  type="textarea"
                  placeholder="Steps: reason, Blood pressure: reason"
                />,
              )}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item"
              label={
                <>
                  <div className="question-number">7</div>Study Website
                </>
              }
              colon={false}
            >
              {getFieldDecorator('website', {
                initialValue: study.website,
                rules: [
                  {
                    type: 'url',
                    message: 'Please enter a valid URL starting with http:// or https://',
                  },
                ],
              })(<Input type="textarea" placeholder="https://www.example.com" />)}
            </StyledForm.Item>

            <StyledForm.Item
              className="form-item upload"
              label={
                <>
                  <div className="question-number">8</div>Upload Institution Icons
                </>
              }
              colon={false}
            >
              {getFieldDecorator('institutionIcons', {
                initialValue: institutionFiles,
                rules: [
                  {
                    validator: (rule, value, callback) => {
                      try {
                        if (value.length && value[value.length - 1].size > 250000) {
                          throw new Error('Maximum size for icons is 250KB!');
                        }
                      } catch (err) {
                        callback(err);
                        return;
                      }
                      callback();
                    },
                    message: 'Please make sure provided icon is under 250KB',
                  },
                ],
              })(<IconUpload files={institutionFiles} />)}
            </StyledForm.Item>

            <StyledFooter>
              <StyledForm.Item>
                <Button type="primary" htmlType="submit" disabled={isLoading}>
                  SAVE CHANGES
                </Button>
              </StyledForm.Item>
            </StyledFooter>
          </StyledForm>
        </Col>
      </Row>
      <Modal
        title="Confirm changes to data collected"
        okButtonProps={{ form: 'edit-study', key: 'submit', htmlType: 'submit' }}
        visible={showGDPRNotice}
        onOk={() => {
          setConfirmSubmit(true);
          setShowGDPRNotice(false);
        }}
        onCancel={() => setShowGDPRNotice(false)}
      >
        <p>
          You are about to change the data collected from the study participants and/or the data
          collection reasons. According to GDPR this requires the consent of the participants that
          have already joined the study. No more data will be collected from them until they have
          accepted the changes. If no approval is given, the participant will immediately be removed
          from the study.
        </p>
      </Modal>
    </>
  );
};

StudyForm.propTypes = {
  study: PropTypes.object,
  form: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  invitations: PropTypes.array,
};

StudyForm.defaultProps = {
  study: {},
  form: {},
  invitations: [],
};

export default Form.create()(StudyForm);
